// Require optional shared components
require(['componentLoader'], function(helper) {

  var config = THEHUT.config;

  // Make these modules dependencies
  helper.promote([
    'productPageSlider',
    'carousel',
    'carouselSet',
    'maps',
    'quantitySpinner',
    'sliderSet'
  ]);

  helper.requireDependencies();

});

/* Youtube videos /videos/youtube-main.js - load the API and render via youtubePlayer */
require(['youtube'], function(youtube) {
  youtube('.js-youtube-video').done(function(videosArray) {
    // ytVideosArray: array of Youtube videos and player Api controls for further customisation

    var activeYTThumbClass = 'widget-youtube__gallery__menu__items__item--active';
    $('.youtube-video__gallery__menu__item').on('click', function() {
      $('.youtube-video__gallery__menu__item').removeClass(activeYTThumbClass);
      $(this).addClass(activeYTThumbClass);
    });
  });
});

var deps = [
  'jquery',
  'colourswatches',

  // Undeclared
  'colourswatchesTpl',
  'common'
];

require(deps, function($, colourswatches) {
  colourswatches.colourSwatchesForSelectBoxWithLabel('opts-4');

  // Bundle Products Accordion
  var accordionHeadings = ('.bundle-section .widget-header');
  var accordionSiblings = $(accordionHeadings).siblings('.bundle-product-wrap');

  $(accordionSiblings).first().slideToggle().parent().addClass('selected');
  $(accordionHeadings).click(function() {
    var $this = $(this).siblings('.bundle-product-wrap');
    if ($this.is(':animated')) {
      return false;
    }
    if ($this.parent().hasClass('selected')) {
      $this
        .slideToggle()
        .parent()
        .removeClass('selected');

    } else {
      $this.parent()
        .removeClass('selected')
        .find(accordionSiblings)
        .slideUp();
      $this
        .slideToggle()
        .parent()
        .addClass('selected');
    }
    return false;
  });


  /*** BUNDLE FUNCTIONALITY ***/ // Query's? IMPLEMENTED BY TOM S FEDs

  if (document.querySelectorAll('[data-component="ebundle"]').length) {
    require(['bundleController'], function(bundleController) {
      var $bundleWrapper = $('[data-component="ebundle"]');

      bundleController.init({
        $bundleWrapper: $bundleWrapper,
        $products: $bundleWrapper.find('.item'),
        $bundleBtn: $bundleWrapper.find('.bundle-button'),
        $bundleSummary: $bundleWrapper.find('.bundle-summary'),
        footerDistance: $('.footer').offset().top
      });
    });
  }

/*** END OF BUNDLE FUNCTIONALITY ***/
});

require(['jquery'], function($) {
  // header search display
  // this should probably be moved out to a code module
  var activateSearch = function() {
    $('.header').addClass('search-open');
  };
  var deactivateSearch = function() {
    var input = $('.search-input');
    if (input.val() === '' && !input.is(':focus')) {
      $('.header').removeClass('search-open');
    }
  };

  $('.js-header-search').on('click', activateSearch);

  $('.icon-input-reset').on('click', deactivateSearch);


  //bind search [de]activation to throttled scroll handler
  $(document).on('scroll', throttle(function() {
    if (document.body.scrollTop < 22) {
      deactivateSearch();
    } else {
      activateSearch();
    }
  }, 500));

  var searchResetBtnStyle = function() {
    var input = $(this);
    var resetBtn = input.siblings('.btn-search-reset');
    if (input.val() === '') {
      input.removeClass('nologo');
      resetBtn.removeClass('icon-menu-close').addClass('icon-input-reset');
    } else {
      input.addClass('nologo');
      resetBtn.removeClass('icon-input-reset').addClass('icon-menu-close');
    }
  };

  //detect input in search
  $('.search-input').on('keyup change input focus', searchResetBtnStyle);

  $('select[name="pageNumber"]').on('change', function() {
    $(this).parent('form').submit();
  });

  // Borrowed/adapted from underscore.js
  // Returns a function, that, when invoked, will only be triggered at most once
  // during a given window of time. Normally, the throttled function will run
  // as much as it can, without ever going more than once per `wait` duration;
  // but if you'd like to disable the execution on the leading edge, pass
  // `{leading: false}`. To disable execution on the trailing edge, ditto.
  function throttle(func, wait, options) {
    var fnow = Date.now || function() {
      return new Date().getTime();
    };
    var context,
      args,
      result;
    var timeout = null;
    var previous = 0;
    if (!options)
      options = {};
    var later = function() {
      previous = options.leading === false ? 0 : fnow();
      timeout = null;
      result = func.apply(context, args);
      if (!timeout)
        context = args = null;
    };
    return function() {
      var now = fnow();
      if (!previous && options.leading === false)
        previous = now;
      var remaining = wait - (now - previous);
      context = this;
      args = arguments;
      if (remaining <= 0 || remaining > wait) {
        if (timeout) {
          clearTimeout(timeout);
          timeout = null;
        }
        previous = now;
        result = func.apply(context, args);
        if (!timeout)
          context = args = null;
      } else if (!timeout && options.trailing !== false) {
        timeout = setTimeout(later, remaining);
      }
      return result;
    };
  }

});


define(deps, function() {



  var RYINT = function() {

    var self = this;

    function initGoogleMap() {
      // Function for populating the google maps on salon pages
      var locationMap = $('#map-canvas');
      if (locationMap.length) {

        if (siteObj.maps.postcode.length && siteObj.maps.postcode !== '') {

          siteObj.fn.loadmap = function() {

            var geocoder = new google.maps.Geocoder();

            var contentString = '<div class="googleMaps">' +
              '<h2 class="googleMaps_title">' +
              siteObj.maps.title +
              '</h2>' +
              '<div class="googleMaps_address">' +
              siteObj.maps.address +
              '</div>' +
              '</div>';

            geocoder.geocode({
              'address': siteObj.maps.postcode
            }, function(results, status) {
              if (status === google.maps.GeocoderStatus.OK) {

                var location = results[0].geometry.location,
                  lat = location.lat(),
                  lng = location.lng(),
                  latlng = new google.maps.LatLng((lat + 0.005), lng);

                var mapOptions = {
                  zoom: 14,
                  center: location,
                  mapTypeId: google.maps.MapTypeId.ROADMAP
                };

                var map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);

                map.panTo(latlng);

                var marker = new google.maps.Marker({
                  position: location,
                  map: map,
                  title: siteObj.maps.postcode
                });

                var infowindow = new google.maps.InfoWindow({
                  content: contentString
                });

                infowindow.open(map, marker);

                google.maps.event.addListener(marker, 'click', function() {
                  infowindow.open(map, marker);
                });

              } else {
                alert('Geocode was not successful for the following reason: ' + status);
              }
            });
          };

          siteObj.fn.loadScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyBRWqpVDemRjVjXtI_FrUBpDoQXTvBnfhU&v=3.exp&sensor=false&callback=siteObj.fn.loadmap');
        }
      }
    }


    function init() {

      self.initGoogleMap();

      /* Javascript Healthcheck - Please keep this at the bottom of the file. */
      var healthCheck = document.createElement('span');
      healthCheck.id = 'jshealthcheck';
      document.body.appendChild(healthCheck);
    }

    self.init = init;
    self.initGoogleMap = initGoogleMap;
  };

  var ryint = new RYINT();
  ryint.init();
  return ryint;
});
